<template>
  <div class="Lobby">
    <h1>{{ $t('messages.welcome') }}</h1>
    <h2>{{ currentUser.name }}</h2>


    <h2>{{ $t('inputs.user.team') }}</h2>
    <d-button ref="button1" :disabled="isTeam1Disabled && team !== 1" :class="{active: team === 1}"
              @click="selectTeam(1)" class="button">{{
        $t('names.team', [1])
      }}
    </d-button>

    <d-button ref="button2" :disabled="isTeam2Disabled && team !== 2" :class="{active: team === 2}"
              @click="selectTeam(2)" class="button">{{
        $t('names.team', [2])
      }}
    </d-button>

    <d-button ref="button3" :disabled="isJpDisabled && team !== 59" :class="{active: team === 59}"
              @click="selectTeam(59)"
              class="button">{{
        $t('names.jp')
      }}
    </d-button>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import DButton from "@/components/DButton";

export default {
  name: "Lobby",
  components: {DButton},
  computed: {
    ...mapFields(['currentUser']),
  },
  data() {
    return {
      team: "",
      players: [],

      isJpDisabled: false,
      isTeam1Disabled: false,
      isTeam2Disabled: false,
    }
  },
  methods: {
    isTeamAvailable(team) {
      if (team === 59) {
        return !this.players.some((x) => x.team === 59)
      }
      return this.players.filter(x => x.team === team).length < 2
    },
    selectTeam(team) {
      if (this.team === team) {
        this.team = 0
        this.$socket.invoke("JoinTeam", this.currentUser.code.toUpperCase(), this.currentUser.id, 0).then((d) => {
          console.log(d)
        }).catch(function (err) {
          return console.log(err.toString())
        })
        return
      }

      this.team = team
      this.$socket.invoke("JoinTeam", this.currentUser.code.toUpperCase(), this.currentUser.id, team).then((d) => {
        console.log(d)
      }).catch(function (err) {
        return console.log(err.toString())
      })
    }
  },
  sockets: {
    RoomData(...data) {
      this.players = data[0][0].players
      this.isJpDisabled = this.players.some((x) => x.team === 59)
      this.isTeam1Disabled = this.players.filter(x => x.team === 1).length >= 2
      this.isTeam2Disabled = this.players.filter(x => x.team === 2).length >= 2

      if (this.players.some(x => x.id === this.currentUser.id)) {
        this.team = this.players.find(x => x.id === this.currentUser.id).team
      }

      this.$nextTick(() => {
        if (this.$refs.length < 3)
          return
        this.$refs.button1.$forceUpdate()
        this.$refs.button2.$forceUpdate()
        this.$refs.button3.$forceUpdate()
      })
    },
    PlayerData(...data) {
      let index = this.players.findIndex(x => x.id === data[0][0].id)
      if (index >= 0) {
        this.players[index] = data[0][0]
      } else {
        this.players.push(data[0][0])
      }

      this.$nextTick(() => {
        if (this.$refs.length < 3)
          return
        this.$refs.button1.$forceUpdate()
        this.$refs.button2.$forceUpdate()
        this.$refs.button3.$forceUpdate()
      })
    },

    TeamJoined(...data) {
      // console.log("PLAYER JOINED TEAM", data[0][0])
      let index = this.players.findIndex(x => x.id === data[0][0].id)
      if (index >= 0) {
        this.players[index] = data[0][0]
      } else {
        this.players.push(data[0][0])
      }

      setTimeout(() => {
        this.isJpDisabled = this.players.some((x) => x.team === 59)
        this.isTeam1Disabled = this.players.filter(x => x.team === 1).length >= 2
        this.isTeam2Disabled = this.players.filter(x => x.team === 2).length >= 2

        if (this.$refs.length < 3)
          return
        this.$refs.button1.$forceUpdate()
        this.$refs.button2.$forceUpdate()
        this.$refs.button3.$forceUpdate()
      }, 100)
    },
  },
  mounted() {

    this.$socket.start({
      log: true,
      id: this.currentUser.id,
      roomCode: this.currentUser.code.toUpperCase(),
      name: this.currentUser.name
    });

    this.$socket.once('init', () => {
      let avatar = btoa(this.currentUser.avatar.replace(/[\u00A0-\u2666]/g, function (c) {
        return '&#' + c.charCodeAt(0) + ';';
      }))
      this.$socket.invoke("SetAvatar", this.currentUser.code.toUpperCase(), this.currentUser.id, avatar).then((d) => {
        console.log(d)
      }).catch(function (err) {
        return console.log(err.toString())
      })
    })


  },
  beforeDestroy() {
    this.$socket.stop()
  }
}
</script>

<style scoped lang=scss>
.Lobby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: Bungee, sans-serif !important;
    font-size: 60px;
    margin-bottom: 16px;
    margin-top: 10px;
  }

  h1 + h2 {
    margin-top: 0;
    font-size: 40px;
    margin-bottom: 75px;
  }

  h2:not(h1 + h2) {
    margin-top: 0;
    font-size: 25px;
    margin-bottom: 0;
  }

  .button {
    width: 300px;
    margin-top: 30px;

    &:not(.active) {
      //background: red;
    }

  }
}
</style>

<style lang=scss>
.button {
  button, .background {
    transition: .2s background-color;
  }

  &:not(.active):not(:disabled) {
    button:not(.active):not(:disabled) {
      background: var(--licht-achtergrond);

      & ~ .background {
        background: var(--licht-achtergrond);
      }

    }
  }

}
</style>